<template>
  <full-screen-loading />
</template>
<script>

import FullScreenLoading from '@/common/components/FullScreenLoading.vue'

export default {
  name: 'Logout',
  components: { FullScreenLoading },
  mounted() {
    this.logoutUser()
  },
  methods: {
    async logoutUser() {
      await this.$store.dispatch('user/logoutUserFromOtherApp')
      this.$tabEvent.emit('logout-user')
      await this.$store.dispatch('user/logoutUserLocalOnly')
      await this.$router.push('/login')
    },
  },
}
</script>

<style scoped>

</style>
